import * as React from 'react'
import * as Styles from "../styles/form4.module.scss"

const OffichoursBookingForm = ({ data }) => {

    const currentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('')
    return (
        <div>

            <form action="/api/submit-officehours-form" method="POST" class={Styles.form} target="hiddenFrame">

                <fieldset>

                    <h2>Termin in einer Sprechstunde buchen</h2>
                    <div class={Styles.formGroup}>
                        <label class={Styles.label} for="dateSelect">Termin
                            <div class={Styles.field}>
                                <select class="form-control" name="dateSelect" id="select-1646747549721">

                                    {
                                        data.allAirtableOfficehours.edges.filter(edge => edge.node.data.compareDate >= currentDate)
                                            .slice(0, 3)
                                            .map((edge) => (

                                                <option name="dateSelect" id={edge.node.recordId} value={edge.node.recordId}  >
                                                    {edge.node.data.Date + ' um ' + edge.node.data.Time + ' Uhr '}
                                                </option>
                                            ))
                                    }
                                </select>
                            </div>
                        </label>
                    </div>

                    <div class={Styles.formGroup}>
                        <label class={Styles.label} for="Name">Name
                            <div class={Styles.field}>
                                <input id="Name" name="name" type="text" placeholder="Vor- und Nachname" class="form-control input-md" required="" />
                            </div>
                        </label>
                    </div>

                    <div class={Styles.formGroup}>
                        <label class={Styles.label} for="email">E-Mail
                            <div class={Styles.field}>
                                <input id="email" name="email" type="text" placeholder="name@company.com" class="form-control input-md" />
                            </div>
                        </label>
                    </div>

                    <div class={Styles.hidden}>
                        <label class={Styles.label} for="email">Honey Pot
                            <div class={Styles.field}>
                                <input id="hp" name="hp" type="text" class="form-control input-md" placeholder="Nur von Robots auszufüllen" />
                            </div>
                        </label>
                    </div>


                    <div class={Styles.formGroup}>
                        <label class={Styles.label} for="remark">Fragen
                            <div class={Styles.field}>
                                <textarea class="form-control" id="remark" name="remark"></textarea>
                            </div>
                        </label>
                    </div>

                </fieldset>
                <button>
                    Buchung abschicken
                </button>

            </form>
            <iframe name="hiddenFrame" title="Status Message" style={{ height: '7em', border: 'none', width: '100%' }} > <b>Bitte Buchungsdaten eingeben</b> </iframe>
        </div>
    )
}
export default OffichoursBookingForm 